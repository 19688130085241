import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container } from "react-bootstrap"

const SecondPage = () => (
    <Layout pageInfo={{ pageName: "about-site" }}>
        <SEO title="about-site" description="Crimson R Games was founded in 2012 by me(Aldo Pedro Rangel Montiel). It started just as a simple Blog(powered with Blogger) with some posts about programming, videogames and systems." />
        <Container className="mt-3 mb-3">
            <div >
                <h1>About Crimson R Games:</h1>
            </div>
            <div >
                <p>Crimson R Games was founded in January 17 2012 by me(Aldo Pedro Rangel Montiel).
                It started just as a simple Blog(powered with Blogger) with some posts about programming, videogames and systems;
                since then I've been adding more content, like tutorials about technologies
                I use or find interesting or to show case some of the projects that I've been involved in
                some way or that are completely mine.
                </p>
                <br />
                <p>
                    More recently in 2017, I've started to use Crimson R Games
                    as the brand behind my games and apps and using this site as a
                    portfolio of some of my work, while also keeping it as my development blog.
                 <br />
                    Ever since then I've been focusing more on development of other stuff instead of making
                    the site bigger, however, in 2020 it was revamped according to newer technologies.
                    As of 2021 the site has become what I had envisioned: the official site for everything I make.
                </p>
                <p>
                    Crimson R games was(and in a way still is) developed with Gatsby, with a bunch of node packages that made making the site very easy and fun.

                </p>
            </div>
            <Link to="/">Go back to the homepage</Link>
        </Container>

    </Layout>
)

export default SecondPage
